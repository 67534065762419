<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein/personen" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-account-plus</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          PERSON ERSTELLEN
        </h3>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center" class="mt-4">
        <v-col cols="12">
          <v-card
            :dark="!template.light"
            :light="template.light"
            class="rounded-xl pa-4"
          >
            <v-row justify="center">
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.vorname"
                  :rules="[rules.required, rules.name]"
                  label="Vorname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.name"
                  :rules="[rules.required, rules.name]"
                  label="Nachname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.adresse"
                  :rules="[rules.required, rules.street, rules.streetnr]"
                  label="Straße + Nr."
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.plz"
                  :rules="[rules.required, rules.plz]"
                  label="Postleitzahl"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.ort"
                  :rules="[rules.required, rules.name]"
                  label="Ort"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-text-field
                  filled
                  rounded
                  v-model="new_user.telefon"
                  :rules="[rules.required, rules.name]"
                  label="Telefonnummer"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0">
                <v-select
                  filled
                  rounded
                  :items="aktivitaeten"
                  label="Aktivität"
                  v-model="new_user.aktivitaet"
                  v-if="aktivitaeten.length > 0"
                ></v-select>
              </v-col>
              <v-col cols="12" md="10">
                <ggswitches
                  :geimpft="false"
                  :getestet="false"
                  :genesen="false"
                  :datum="false"
                  @valid="validate_ggg($event)"
                  @change="get_ggg_data($event)"
                ></ggswitches>
              </v-col>
              <v-col cols="12" md="10">
                <span class="font-weight-bold">
                  Mit meiner Unterschrift bestätige ich, dass ich die
                  <a
                    :href="
                      template.datenschutz
                        ? template.datenschutz.link
                        : '/datenschutz'
                    "
                    target="_blank"
                  >
                    Datenschutzinformationen
                  </a>
                  zur Kenntnis genommen habe.
                </span>
                <v-card :color="template.colors.blocks" class="rounded-xl">
                  <VueSignaturePad
                    width="100%"
                    height="300px"
                    ref="signaturePad"
                  />
                </v-card>
              </v-col>

              <v-col cols="12" md="10" class="py-0">
                <v-btn
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  block
                  rounded
                  large
                  :disabled="!ggg_valid"
                  :loading="creating"
                  @click="save()"
                >
                  Person speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="error" multi-line color="danger">
      {{ errortext }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'
import ggswitches from '../2G-Regel/switches.vue'

export default {
  name: 'Zuschauer-erfassen',
  components: {
    ggswitches,
  },
  data() {
    return {
      new_user: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
        aktivitaet: 'Zuschauer',
      },
      aktivitaeten: [],
      created: false,
      creating: false,
      valid: false,
      error: false,
      errortext: '',
      ggg: {
        getested: false,
        genesen: false,
        geimpft: false,
      },
      ggg_valid: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        email_confirm: (v) =>
          v == this.new_user.email
            ? true
            : 'Die E-Mail Adresse stimmt nicht überein.',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      characters: 'a-z,A-Z,0-9',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (
      this.user.data.aktivitaeten.length >
      this.template.var.standard_aktivitaeten.length
    ) {
      this.user.data.aktivitaeten.forEach((aktivitaet) => {
        if (
          !this.template.var.standard_aktivitaeten.includes(aktivitaet) ||
          this.template.var.public_aktivitaeten.includes(aktivitaet)
        ) {
          this.aktivitaeten.push(aktivitaet)
        }
      })
    }
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    back() {
      router.push('/verein/personen')
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (this.$refs.form.validate() && !isEmpty) {
        this.creating = true
        this.new_user = {
          vorname: this.new_user.vorname.trim(),
          name: this.new_user.name.trim(),
          adresse: this.new_user.adresse.trim(),
          plz: this.new_user.plz.trim(),
          ort: this.new_user.ort.trim(),
          telefon: this.new_user.telefon.trim(),
          aktivitaet: this.new_user.aktivitaet,
          geimpft: this.ggg.geimpft || false,
          genesen: this.ggg.genesen || false,
          geburtsdatum: this.ggg.geburtsdatum || '',
        }
        this.new_user.signature = data
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Personen')
          .add(this.new_user)
          .then(() => {
            this.back()
          })
      } else {
        if (isEmpty) {
          this.error = true
          this.errortext =
            'Du musst mit deiner Unterschrift bestätigen, dass du die Datenschutzinformationen zur Kenntnis genommen hast.'
        }
      }
    },
  },
}
</script>
